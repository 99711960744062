import React, { useCallback, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { LayoutWrapper } from '../../components/layout/layoutWrapper'
import { AppLocale, DownloadLink } from '@hrk/types'
import {
  CoursePage,
  BookmarkDialogProps,
  mapCourse,
  BookmarkList,
  PaginationComponent,
  SearchInputWithFilters,
  PageData,
  TrainingOfferFilterValues,
  LoadingSpinner,
} from '@hrk/huw-module-library'
import { useTranslations } from '../../hooks/useTranslations'
import { useKnowledgeGroups } from '../../hooks/useKnowledgeGroups'
import { CourseJsonLd } from 'gatsby-plugin-next-seo'
import { SiteMetadata, useSiteMetadata } from '../../hooks/useSiteConfig'
import { Seo } from '../../components/seo/Seo'
import { useUserCodeContext } from '../../context/UserCodeContext'
import { useBookmarks } from '../../hooks/useBookmarks'
import { useLocale } from '../../hooks/useLocale'
import { useSearchDocumentsQuery } from 'frontendApiGraphqlTypes'
import useCookie from '../../hooks/useCookie'
import { localizedSlug } from '../../utils/localizedSlug'
import { useQueryParam } from '../../hooks/useQueryParam'
import { useNavigationItems } from '../../hooks/useNavigationItems'
import { WeiterbildungsandgebotPageContext } from '../../@types/PageContext.type'
import { hoursToISO8601Duration } from '../../utils/hoursToISO8601Duration'
import { periodToHours } from '../../utils/periodToHours'

interface IWeiterbildungsAngebotTemplateProps {
  pageContext: WeiterbildungsandgebotPageContext
}

const WeiterbildungsAngebotTemplate = (props: IWeiterbildungsAngebotTemplateProps): JSX.Element => {
  const strapiTrainingOffer = props.pageContext.json
  const { pageLocale } = useLocale()
  const site: SiteMetadata = useSiteMetadata(pageLocale)
  const knowledgeGroups = useKnowledgeGroups()
  const {
    bookmarkDialog: bookmarkTranslations,
    common: commonTranslations,
    course: courseTranslations,
    search: searchTranslations,
    download: downloadTranslations,
    search: {
      categoryNames: { TrainingOffers: TrainingOfferCategoryTranslation },
    },
    layout: { backToSearchResults: backToSearchResultsTranslation },
  } = useTranslations()

  const uiTexts = props.pageContext.uiTexts.filter && props.pageContext.uiTexts.filter((uiText) => !!uiText.htmlContent)
  const currentSlug = props.pageContext.slug
  const currentTitle = props.pageContext.title
  const currentLocale = props.pageContext.locale as AppLocale
  const otherLocales =
    props.pageContext.localizations?.map((l) => ({
      locale: l?.locale as AppLocale,
      slug: l?.slug as string,
    })) || []
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_t, setQueryCategory] = useQueryParam('t', '')
  const [_f, setQueryFilterValue] = useQueryParam('f', '')
  const [_q, setQueryTerm] = useQueryParam('q', '')
  const [_p, setQueryPage] = useQueryParam('p', '')
  const [_s, setQueryPerPage] = useQueryParam('s', '15')
  const [_a, setQuerySort] = useQueryParam('a', '')
  /* eslint-enable @typescript-eslint/no-unused-vars */
  const [searchMode, setSearchMode] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      let a = !window.location.href.includes(localizedSlug(pageLocale, '/suche'))
      a = a && window.location.href.includes(localizedSlug(pageLocale, '/weiterbildungsangebot'))
      a = a && _p !== ''
      a = a && _t === 'TrainingOffers'
      setSearchMode(a)
    }
  }, [_p, _t, pageLocale])

  // also define a nice name, because the cms stores unique filenames
  // and the nginx doesn't know anything about better filenames (will simply download the unique filename)
  const currentPdfUrl: DownloadLink | undefined = props.pageContext.pdfUrl
    ? {
        url: props.pageContext.pdfUrl,
        target: '_blank',
        title: downloadTranslations.downloadTitleHsp,
        downloadFilename: `Weiterbildungsangebot-${strapiTrainingOffer.id}-${currentLocale}.pdf`,
      }
    : undefined

  const { collectBreadCrumbs } = useNavigationItems()
  let currentPagePath = collectBreadCrumbs({ ...props.pageContext, type: 'TrainingOffers' })
  // we need to manipulate the search within this template (not in the collectBreadCrumbs-fn) becasue we need the dynamic value from _t "category"
  currentPagePath = [
    currentPagePath[0], // home
    _t === 'TrainingOffers'
      ? {
          slug: localizedSlug(pageLocale, '/suche'),
          name: TrainingOfferCategoryTranslation,
          description: backToSearchResultsTranslation,
        }
      : currentPagePath[1], // search (?)
    ...currentPagePath.slice(2), // everything else
  ]

  const courseTitle = props.pageContext.json.title
  const courseProvider = props.pageContext.json.universityAddressName
  const courseDescription = props.pageContext.json.learningResult

  const pageUrl = `${site.metadata.siteUrl}${currentSlug.endsWith('/') ? currentSlug : currentSlug + '/'}`

  const { userCode } = useUserCodeContext()
  const [userWitMatch, setUserWitMatch] = useState<number | undefined>(undefined)

  const { bookmarkLists, updateBookmarkList, createBookmarkList } = useBookmarks()

  const bookmarkDialogProps: BookmarkDialogProps = {
    bookmark: mapCourse(props.pageContext.json),
    bookmarkLists,
    onSaveBookmarkList: (list: BookmarkList) => {
      'id' in list ? updateBookmarkList(list) : createBookmarkList(list)
    },
    onCancel: () => void 0,
    translations: bookmarkTranslations,
    navigate,
  }

  // For search mode
  const [searchTerm, setSearchTerm] = useState<string>(_q ?? '')
  const [pageData, setPageData] = useState<PageData | undefined>(undefined)
  const [internalLoading, setInternalLoading] = useState(false)

  const onSearchButtonClick = () => {
    const searchParams = {
      ...{ q: searchTerm }, // query/term
      ...{ ...(_t ? { t: _t } : null) }, // type
      ...{ ...(_a ? { a: _a } : null) }, // sort
      ...{ ...(_f ? { f: _f } : null) }, // filter
      ...{ p: '1' },
      ...{ s: '15' },
    }
    const new_params = new URLSearchParams([...Object.entries<string>(searchParams)]).toString()
    navigate(`${localizedSlug(pageLocale, '/suche')}?${new_params}`)
  }

  const handleFiltersChange = (newFilterValues) => {
    setSearchMode(false)
    const searchParams = {
      ...{ ...(_q ? { q: _q } : null) },
      ...{ ...(_t ? { t: _t } : null) },
      ...{ ...(_s ? { s: '15' } : null) },
      ...{ ...(_a ? { a: _a } : null) },
      ...{ ...(_f ? { f: JSON.stringify(newFilterValues) } : null) },
      ...{ ...(_p ? { p: '1' } : null) },
    }
    const newParams = new URLSearchParams([...Object.entries<string>(searchParams)]).toString()
    navigate(`${localizedSlug(pageLocale, '/suche')}?${newParams}`)
  }

  const { cookie, updateCookie } = useCookie('huw_values', JSON.stringify({ wa_id: `wa-${strapiTrainingOffer.id}` }))
  useEffect(() => {
    updateCookie(JSON.stringify({ wa_id: `wa-${strapiTrainingOffer.id}` }), 365)
  }, [strapiTrainingOffer.id, updateCookie])

  const { loading } = useSearchDocumentsQuery({
    context: { headers: { 'Accept-Language': pageLocale } },
    fetchPolicy: 'cache-first',
    variables: {
      term: _p ? _q?.replace(/-/g, ' ') : strapiTrainingOffer.title,
      full: false,
      editorialPages: false,
      trainingOffers: true,
      universities: false,
      page: +(_p || 1),
      pageSize: +(_s || 1),
      sort: _a,
      trainingOffersFilter: (_f ? JSON.parse(_f) : null) as TrainingOfferFilterValues,
      universitiesFilter: null,
      userCode,
    },
    onError: (error) => {
      console.error('Error searching', error)
    },
    onCompleted: ({ trainingOffers }) => {
      if (trainingOffers && trainingOffers.pageInfo) {
        setPageData({
          page: trainingOffers.pageInfo.page,
          found: trainingOffers.pageInfo.found,
          pageSize: 1,
        })
      }
      if (trainingOffers) {
        setUserWitMatch(trainingOffers.documents[0].document.witMatch ?? undefined)
      }
      if (!searchMode) return
      const nextSlug = trainingOffers?.documents[0].document.pageSlug
      if (
        nextSlug &&
        trainingOffers?.documents[0].document.id !== `wa-${strapiTrainingOffer.id}` &&
        trainingOffers?.documents[0].document.id !== JSON.parse(cookie).wa_id
      ) {
        setInternalLoading(true)
        updateCookie(JSON.stringify({ wa_id: trainingOffers?.documents[0].document.id }), 365)
        const searchParams = {
          ...{ ...(_q ? { q: _q } : null) },
          ...{ ...(_t ? { t: _t } : null) },
          ...{ ...(_s ? { s: _s } : null) },
          ...{ ...(_a ? { a: _a } : null) },
          ...{ ...(_f ? { f: _f } : null) },
          ...{ ...(_p ? { p: _p } : null) },
        }
        const new_params = new URLSearchParams([...Object.entries<string>(searchParams)]).toString()
        navigate(`/${nextSlug}?${new_params}`, { replace: true })
      }
    },
  })

  const location = strapiTrainingOffer?.locations
    ?.map(({ name }) => name ?? '')
    .concat(strapiTrainingOffer.cooperatingUniversities.flatMap((name) => name.locations))
  const courseMode = strapiTrainingOffer.eventFormat.sessions.every(({ presence }) => presence)
    ? 'onsite'
    : strapiTrainingOffer.eventFormat.sessions.every(({ synchronous }) => synchronous)
    ? 'online'
    : 'blended'
  const courseWorkloadHours = periodToHours(strapiTrainingOffer.eventFormat.duration)
  const courseWorkloadMin = hoursToISO8601Duration(courseWorkloadHours[0])
  const courseWorkloadMax = hoursToISO8601Duration(courseWorkloadHours[1])
  const costCategory = strapiTrainingOffer.cost.totalCostNumber === 0 ? 'free' : 'paid'

  // navigate using keyboard arrow keys
  const traverseFunction = useCallback(
    (event) => {
      if (event.target.id !== 'gatsby-focus-wrapper' || !searchMode) return
      if (event.code === 'ArrowRight') {
        setQueryPage((v) => `${Number(v) + 1}`)
      }
      if (event.code === 'ArrowLeft') {
        setQueryPage((v) => `${Number(v) - 1}`)
      }
    },
    [searchMode, setQueryPage],
  )
  useEffect(() => {
    document.addEventListener('keydown', traverseFunction, false)
    return () => {
      document.removeEventListener('keydown', traverseFunction, false)
    }
  }, [traverseFunction])

  return (
    <>
      <Seo title={courseTitle} slug={currentSlug} locale={currentLocale} description={courseDescription}></Seo>
      <CourseJsonLd
        name={courseTitle}
        providerName={courseProvider}
        providerUrl={pageUrl}
        description={courseDescription}
        overrides={{
          '@type': 'Course',
          hasCourseInstance: [
            {
              '@type': 'CourseInstance',
              courseWorkload: [courseWorkloadMin, courseWorkloadMax],
              courseMode: courseMode, // google only supports the enum values: [online|onsite|blended]
              location: location,
              offers: [], // the instance offers can be empty, but must be present
            },
          ],
          offers: [
            {
              '@type': 'Offer',
              price: strapiTrainingOffer.cost.totalCostNumber,
              priceCurrency: 'EUR',
              category: costCategory,
            },
          ],
        }}
      />
      <LayoutWrapper
        currentLocale={currentLocale}
        currentSlug={currentSlug}
        otherLocales={otherLocales}
        currentTitle={currentTitle}
        currentPath={currentPagePath}
        currentPdfLink={currentPdfUrl}
      >
        {(loading || internalLoading) && <LoadingSpinner />}
        {searchMode && (
          <SearchInputWithFilters
            searchTerm={searchTerm}
            filterValues={_f ? JSON.parse(_f) : null}
            searchTranslations={searchTranslations}
            setSearchTerm={setSearchTerm}
            onSearchButtonClick={onSearchButtonClick}
            handleFiltersChange={handleFiltersChange}
          ></SearchInputWithFilters>
        )}
        <CoursePage
          coursePageData={strapiTrainingOffer}
          updateBookmarkList={updateBookmarkList}
          bookmarkDialogProps={bookmarkDialogProps}
          allKnowledgeGroups={knowledgeGroups}
          coursePageTranslations={courseTranslations}
          coursePageUiTexts={uiTexts}
          navigate={navigate}
          userWitMatch={userWitMatch}
        ></CoursePage>
        <div aria-hidden hidden>
          {JSON.stringify(pageData, null, 2)}
        </div>
        {searchMode && pageData && (
          <PaginationComponent
            data={pageData}
            onPageChange={(page: number) => setQueryPage(`${page}`)}
            onPerPageChange={() => void 0}
            showPossiblePerPageSizes={false}
            translations={searchTranslations?.resultTranslations}
          ></PaginationComponent>
        )}
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return (
    <link
      rel="canonical"
      href={`${site.metadata.siteUrl}${pageContext.slug.endsWith('/') ? pageContext.slug : pageContext.slug + '/'}`}
    />
  )
}

export default WeiterbildungsAngebotTemplate
