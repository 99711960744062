import React from 'react'
import WitWrapper from '../components/wit/witWrapper'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { Seo } from '../components/seo/Seo'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const Wit: React.FC = () => {
  const currentSlug = '/weiterbildungs-interessentest'
  const currentLocale: AppLocale = GenerateLocales.de
  const seoTitle = 'Weiterbildungsinteressentest - Wissen, welche Weiterbildung passt'
  const seoDescription =
    'Finden Sie noch heute mit dem kostenlosen wit - Weiterbildungs-Interessentest ein zu Ihren Interessen und Bedarfen passendes Weiterbildungsangebot!'

  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'EditorialPages' })

  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <LayoutWrapper
        currentSlug={currentSlug}
        currentTitle={seoTitle}
        currentPath={currentPagePath}
        otherLocales={[{ locale: GenerateLocales.en, slug: '/en/interest-test/' }]}
        styleProps={{ overflow: 'visible' }}
      >
        <WitWrapper />
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/weiterbildungs-interessentest/`} />
}

export default Wit
