import React from 'react'
import { BasicSearch } from '../components/search/basicSearch'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'
import { Seo } from '../components/seo/Seo'

const Search: React.FC = () => {
  const currentSlug = '/en/search'
  const seoTitle = 'Search'
  const seoDescription = 'Search further training offers and higher education institues on hoch und weit'
  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'EditorialPages' })

  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={GenerateLocales.en} description={seoDescription}></Seo>
      <LayoutWrapper
        currentLocale={GenerateLocales.en}
        currentSlug={currentSlug}
        currentPath={currentPagePath}
        otherLocales={[{ locale: GenerateLocales.de, slug: '/suche/' }]}
        styleProps={{ overflow: 'visible' }}
      >
        <BasicSearch />
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/en/search/`} />
}

export default Search
