import React, { useCallback, useEffect, useState } from 'react'
import { useLocale } from '../../hooks/useLocale'
import { useUserCodeContext } from '../../context/UserCodeContext'
import { useBookmarks } from '../../hooks/useBookmarks'
import {
  SearchDocumentsDocument,
  SearchDocumentsQuery,
  SearchDocumentsQueryVariables,
  TrainingOfferPageInfoDocument,
  TrainingOfferPageInfoQuery,
  TrainingOfferPageInfoQueryVariables,
  useSearchDocumentsQuery,
} from 'frontendApiGraphqlTypes'
import { LayoutWrapper } from '../../components/layout/layoutWrapper'
import { AppLocale, DownloadLink } from '@hrk/types'
import {
  OrganizationPage,
  BookmarkList,
  BookmarkDialogProps,
  mapOrganisationToBookmark,
  PaginationComponent,
  SearchInputWithFilters,
  PageData,
  UniversityFilterValues,
  LoadingSpinner,
} from '@hrk/huw-module-library'
import { useTranslations } from '../../hooks/useTranslations'
import { Course } from '@hrk/huw-module-library/build/CourseList/CourseList.types'
import { Seo } from '../../components/seo/Seo'
import { SiteMetadata, useSiteMetadata } from '../../hooks/useSiteConfig'
import { navigate } from 'gatsby'
import { useApolloClient } from '@apollo/client'
import { getRandomIntsInRange } from '../../utils/getRandomIntsInRange'
import { CollegeOrUniversityJsonLd } from '../../components/seo/CollegeOrUniversity'
import useCookie from '../../hooks/useCookie'
import { localizedSlug } from '../../utils/localizedSlug'
import { useQueryParam } from '../../hooks/useQueryParam'
import { useNavigationItems } from '../../hooks/useNavigationItems'
import { HochschulportraitPageContext } from '../../@types/PageContext.type'

interface IHochschulPortraitTemplateProps {
  pageContext: HochschulportraitPageContext
}

const HochschulPortraitTemplate = (props: IHochschulPortraitTemplateProps): JSX.Element => {
  const { userCode } = useUserCodeContext()
  const { bookmarkLists, updateBookmarkList, createBookmarkList } = useBookmarks()
  const { pageLocale } = useLocale()
  const client = useApolloClient()
  const [recommendedCourses, setRecommendedCourses] = useState<Course[]>([])
  const {
    bookmarkDialog: bookmarkTranslations,
    common: commonTranslations,
    search: searchTranslations,
    university: universityTranslations,
    download: downloadTranslations,
    search: {
      resultTranslations: { inLanguagePreposition },
      categoryNames: { Universities: UniversityCategoryTranslation },
    },
    layout: { backToSearchResults: backToSearchResultsTranslation },
  } = useTranslations()
  const site: SiteMetadata = useSiteMetadata(pageLocale)
  const universityData = props.pageContext.json
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_t, setQueryCategory] = useQueryParam('t', '')
  const [_f, setQueryFilterValue] = useQueryParam('f', '')
  const [_q, setQueryTerm] = useQueryParam('q', '')
  const [_p, setQueryPage] = useQueryParam('p', '')
  const [_s, setQueryPerPage] = useQueryParam('s', '15')
  const [_a, setQuerySort] = useQueryParam('a', '')
  /* eslint-enable @typescript-eslint/no-unused-vars */
  const [searchMode, setSearchMode] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      let a = !window.location.href.includes(localizedSlug(pageLocale, '/suche'))
      a = a && window.location.href.includes(localizedSlug(pageLocale, '/hochschulen'))
      a = a && _p !== ''
      a = a && _t === 'Universities'
      setSearchMode(a)
    }
  }, [_p, _t, pageLocale])

  // also define a nice name, because the cms stores unique filenames
  // and the nginx doesn't know anything about better filenames (will simply download the unique filename)
  const currentPdfUrl: DownloadLink | undefined = props.pageContext.pdfUrl
    ? {
        url: props.pageContext.pdfUrl,
        target: '_blank',
        title: downloadTranslations.downloadTitleHsp,
        downloadFilename: `Hochschulportrait-${universityData.id}.pdf`,
      }
    : undefined

  useEffect(() => {
    async function loadData() {
      const queryResult = await client
        .query<TrainingOfferPageInfoQuery, TrainingOfferPageInfoQueryVariables>({
          query: TrainingOfferPageInfoDocument,
          context: { headers: { 'Accept-Language': pageLocale } },
          variables: {
            trainingOffersFilter: { universityIds: [`hsp-${universityData.id}`] },
          },
        })
        // Catch 404 not found (and everything else)
        .catch((_) => ({ data: null }))

      if (!queryResult.data || queryResult.data.trainingOffers?.pageInfo.found === 0) return
      /* 
        Because Typesense pages are One-Based values the offset for the pages to get are +1.
        Because the first page is #1 both, the `min` and `max` of the `getRandomIntsInRange` function must be offset.
      */
      const pageOffset = 1
      const randInts = getRandomIntsInRange(pageOffset, queryResult.data.trainingOffers?.pageInfo.found + pageOffset, 6)
      const resultData: any[] = []
      for (const iterator of randInts) {
        const queryResult = await client.query<SearchDocumentsQuery, SearchDocumentsQueryVariables>({
          query: SearchDocumentsDocument,
          context: { headers: { 'Accept-Language': pageLocale } },
          variables: {
            term: '*',
            page: iterator,
            pageSize: 1,
            trainingOffers: true,
            sort: 'title',
            trainingOffersFilter: { universityIds: [`hsp-${universityData.id}`] },
          },
        })
        resultData.push(queryResult.data.trainingOffers?.documents[0].document)
      }
      const foundItems = mapRecommendedCourses(resultData.flat(), randInts, universityData.addressName ?? '')
      setRecommendedCourses(Array.isArray(foundItems) ? foundItems : [])
    }
    loadData()
  }, [client, universityData.id, universityData.addressName, pageLocale])

  const currentSlug = props.pageContext.slug

  const currentTitle = props.pageContext.title
  const currentLocale = props.pageContext.locale as AppLocale
  const otherLocales =
    props.pageContext.localizations?.map((l) => ({
      locale: l?.locale as AppLocale,
      slug: l?.slug as string,
    })) || []

  const { collectBreadCrumbs } = useNavigationItems()
  let currentPagePath = collectBreadCrumbs({ ...props.pageContext, type: 'Universities' })
  // we need to manipulate the search within this template (not in the collectBreadCrumbs-fn) becasue we need the dynamic value from _t "category"
  currentPagePath = [
    currentPagePath[0], // home
    _t === 'Universities'
      ? {
          slug: localizedSlug(pageLocale, '/suche'),
          name: UniversityCategoryTranslation,
          description: backToSearchResultsTranslation,
        }
      : currentPagePath[1], // search (?)
    ...currentPagePath.slice(2), // everything else
  ]

  function mapRecommendedCourses(documents: any, randomPageNumbers: number[], universityName: string): Course[] {
    return (
      documents?.map(
        (item, index) =>
          ({
            language: item.language,
            eventFormat: item.eventFormat?.join(', '),
            title: item.title,
            type: item.type,
            degree: item.degree,
            // Slug is an absolute path with university name filter and random page number
            pageSlug: `/${item.pageSlug}?t=TrainingOffers&s=1&f={"universityNames"%3A["${universityName}"]}&p=${randomPageNumbers[index]}&a=title`,
            witMatch: item.witMatch,
          } as Course),
      ) || []
    )
  }

  // this aligned with the typesense search result
  const seoTitle = props.pageContext.json.universityName ?? props.pageContext.json.addressName
  const seoDescription = props.pageContext.json.shortDescription
  const pageUrl = `${site.metadata.siteUrl}${currentSlug.endsWith('/') ? currentSlug : currentSlug + '/'}`

  const bookmarkDialogProps: BookmarkDialogProps = {
    bookmark: mapOrganisationToBookmark(universityData, props.pageContext.trainingOfferCount),
    bookmarkLists: bookmarkLists,
    onSaveBookmarkList: async (list: BookmarkList): Promise<void> => {
      'id' in list ? updateBookmarkList(list) : createBookmarkList(list)
    },
    onCancel: () => void 0,
    translations: bookmarkTranslations,
    navigate,
  }

  // For search mode
  const [searchTerm, setSearchTerm] = useState<string>(_q ?? '')
  const [pageData, setPageData] = useState<PageData | undefined>(undefined)
  const [internalLoading, setInternalLoading] = useState(false)

  const onSearchButtonClick = () => {
    const searchParams = {
      ...{ q: searchTerm }, // query/term
      ...{ ...(_t ? { t: _t } : null) }, // type
      ...{ ...(_a ? { a: _a } : null) }, // sort
      ...{ ...(_f ? { f: _f } : null) }, // filter
      ...{ p: '1' },
      ...{ s: '15' },
    }
    const new_params = new URLSearchParams([...Object.entries<string>(searchParams)]).toString()
    navigate(`${localizedSlug(pageLocale, '/suche')}?${new_params}`)
  }

  const handleFiltersChange = (newFilterValues) => {
    setSearchMode(false)
    const searchParams = {
      ...{ ...(_q ? { q: _q } : null) },
      ...{ ...(_t ? { t: _t } : null) },
      ...{ ...(_s ? { s: '15' } : null) },
      ...{ ...(_a ? { a: _a } : null) },
      ...{ ...(_f ? { f: JSON.stringify(newFilterValues) } : null) },
      ...{ ...(_p ? { p: '1' } : null) },
    }
    const new_params = new URLSearchParams([...Object.entries<string>(searchParams)]).toString()
    navigate(`${localizedSlug(pageLocale, '/suche')}?${new_params}`)
  }

  const { cookie, updateCookie } = useCookie('huw_values', JSON.stringify({ hsp_id: `hsp-${universityData.id}` }))
  useEffect(() => {
    updateCookie(JSON.stringify({ hsp_id: `hsp-${universityData.id}` }), 365)
  }, [universityData.id, updateCookie])

  const { loading } = useSearchDocumentsQuery({
    context: { headers: { 'Accept-Language': pageLocale } },
    fetchPolicy: 'cache-first',
    skip: !searchMode,
    variables: {
      term: _q?.replace(/-/g, ' '),
      full: false,
      editorialPages: false,
      trainingOffers: false,
      universities: true,
      page: +(_p ?? 1),
      pageSize: +(_s ?? 15),
      sort: _a,
      trainingOffersFilter: null,
      universitiesFilter: (_f ? JSON.parse(_f) : null) as UniversityFilterValues,
      userCode,
    },
    onError: (error) => {
      console.error('Error searching', error)
    },
    onCompleted: ({ universities }) => {
      if (universities && universities.pageInfo) {
        setPageData({
          page: universities.pageInfo.page,
          found: universities.pageInfo.found,
          pageSize: 1,
        })
      }
      const nextSlug = universities?.documents[0].document.pageSlug
      if (
        nextSlug &&
        universities?.documents[0].document.id !== `hsp-${universityData.id}` &&
        universities?.documents[0].document.id !== JSON.parse(cookie).hsp_id
      ) {
        setInternalLoading(true)
        updateCookie(JSON.stringify({ wa_id: universities?.documents[0].document.id }), 365)
        const searchParams = {
          ...{ ...(_q ? { q: _q } : null) },
          ...{ ...(_t ? { t: _t } : null) },
          ...{ ...(_s ? { s: _s } : null) },
          ...{ ...(_a ? { a: _a } : null) },
          ...{ ...(_f ? { f: _f } : null) },
          ...{ ...(_p ? { p: _p } : null) },
        }
        const new_params = new URLSearchParams([...Object.entries<string>(searchParams)]).toString()
        navigate(`/${nextSlug}?${new_params}`, { replace: true })
      }
    },
  })

  // navigate using keyboard arrow keys
  const traverseFunction = useCallback(
    (event) => {
      if (event.target.id !== 'gatsby-focus-wrapper' || !searchMode) return
      if (event.code === 'ArrowRight') {
        setQueryPage((v) => `${Number(v) + 1}`)
      }
      if (event.code === 'ArrowLeft') {
        setQueryPage((v) => `${Number(v) - 1}`)
      }
    },
    [searchMode, setQueryPage],
  )
  useEffect(() => {
    document.addEventListener('keydown', traverseFunction, false)
    return () => {
      document.removeEventListener('keydown', traverseFunction, false)
    }
  }, [traverseFunction])

  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>

      <CollegeOrUniversityJsonLd
        id={pageUrl}
        name={seoTitle ?? ''}
        url={pageUrl}
        logo={props.pageContext.json.logoUrl ?? ''}
      />

      <LayoutWrapper
        currentLocale={currentLocale}
        currentSlug={currentSlug}
        otherLocales={otherLocales}
        currentTitle={currentTitle}
        shareTitle={`${commonTranslations.universityPortrait} ${seoTitle} ${inLanguagePreposition} ${commonTranslations.huwde}`}
        currentPath={currentPagePath}
        currentPdfLink={currentPdfUrl}
      >
        {(loading || internalLoading) && <LoadingSpinner />}
        {searchMode && (
          <SearchInputWithFilters
            searchTerm={searchTerm}
            filterValues={_f ? JSON.parse(_f) : null}
            searchTranslations={searchTranslations}
            setSearchTerm={setSearchTerm}
            onSearchButtonClick={onSearchButtonClick}
            handleFiltersChange={handleFiltersChange}
          ></SearchInputWithFilters>
        )}
        <OrganizationPage
          {...{
            universityData: universityData,
            updateBookmarkList: updateBookmarkList,
            bookmarkDialogProps: bookmarkDialogProps,
            trainingOfferCount: props.pageContext.trainingOfferCount,
            recommendedCourses,
            translations: universityTranslations,
            socialNetworks: universityData?.general?.socialNetwork ?? {},
          }}
        />
        {searchMode && pageData && (
          <PaginationComponent
            data={pageData}
            onPageChange={(page: number) => setQueryPage(`${page}`)}
            onPerPageChange={() => void 0}
            showPossiblePerPageSizes={false}
            translations={searchTranslations?.resultTranslations}
          ></PaginationComponent>
        )}
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return (
    <link
      rel="canonical"
      href={`${site.metadata.siteUrl}${pageContext.slug.endsWith('/') ? pageContext.slug : pageContext.slug + '/'}`}
    />
  )
}

export default HochschulPortraitTemplate
